import React, { useMemo } from 'react';

import { RootLayout, Section, Stack } from '@layouts';
import { open, ScreenDefinition, sheets, useSheet } from '@navigate';
import { PromptCard } from '@app/_common';
import { Prompts } from '@app/config';

import { PromptDefinition, Route } from '@app/types';
import { GoalType, useFintechQuery } from '@app/data';
import { GET_MONEY_PAGE } from '@app/data/definitions/money';
import { getGoalsBySection, GOAL_TYPE_TO_SLUG, GoalSection } from '@app/constants/goals';
import { AddGoalRow, GoalActions, GoalRow } from '@app/partial/goals';

const GOAL_SECTION_PROMPTS: Record<GoalSection, PromptDefinition> = {
  [GoalSection.Taxes]: Prompts.TAXES_SETUP,
  [GoalSection.Goals]: Prompts.SAVINGS_SETUP,
};

/**
 * The "Money" Page
 *
 * These are split into two sections:
 * "Taxes" and "Goals"
 *
 */
const Money = () => {
  const { open: openSheet } = useSheet();
  const { loading, data } = useFintechQuery(GET_MONEY_PAGE);

  const { sections, totalPercentage } = useMemo(() => {
    const goals = data?.goals || [];

    const totalPercentage = goals?.reduce((total, goal) => {
      return total + goal?.withholdingPercentage;
    }, 0);

    const sections = getGoalsBySection(goals);

    return { sections, totalPercentage };
  }, [data]);

  const addGoal = (goalType: GoalType) => {
    open(Route.GOAL_INTRO, { slug: GOAL_TYPE_TO_SLUG[goalType] });
  };

  // @todo: allow users to edit all percentages at once
  const editPercentage = () => {};

  // opens sheet with transfer options
  const openTransfer = () => {
    openSheet(sheets.TRANSFER_OPTIONS);
  };

  return (
    <RootLayout
      title="Money"
      loading={loading}
      controls={
        <GoalActions
          loading={loading}
          balance={0}
          percentage={totalPercentage}
          editPercentage={editPercentage}
          openTransfer={openTransfer}
        />
      }
    >
      <Stack spacing="2">
        {sections.map((section) => (
          <Section key={section.name} title={section.name} variant="root">
            <Stack spacing="1">
              {/* shows a prompt to setup this section, using the first goal to add */}
              {section.goals.length === 0 && (
                <PromptCard
                  {...GOAL_SECTION_PROMPTS[section.name]}
                  size="ALERT"
                  defaultAction={() => addGoal(section.goalsToAdd[0])}
                />
              )}

              {section.goals.length > 0 && (
                <div>
                  {section.goals.map((goal) => (
                    <GoalRow key={goal.id} goal={goal} />
                  ))}
                </div>
              )}

              {section.goalsToAdd.length > 0 && (
                <div>
                  {section.goalsToAdd.map((goalType) => (
                    <AddGoalRow key={goalType} goalType={goalType} addGoal={addGoal} />
                  ))}
                </div>
              )}
            </Stack>
          </Section>
        ))}
      </Stack>
    </RootLayout>
  );
};

export const MoneyView: ScreenDefinition = {
  name: Route.MONEY,
  component: Money,
  options: {
    title: 'Money',
  },
};
