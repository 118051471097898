import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Loading, useTheme } from '@uikit';
import { webTitle, defaultTitle } from '@app/utils';
import { init } from '@navigate';
import { screens, stacks, AppRoutes } from '@features';
import { useAuth } from '@auth';

init({ screens, stacks });

const App = () => {
  useAuth({ isRoot: true });

  const { themeColors } = useTheme();
  useEffect(() => {
    document.body.style.backgroundColor = themeColors.pageColor;
  }, [themeColors]);

  return (
    <>
      <Helmet titleTemplate={`%s ${webTitle}`} defaultTitle={defaultTitle}>
        <meta name="description" content="A portable benefits platform" />
      </Helmet>
      <Suspense fallback={<Loading full page />}>
        <AppRoutes />
      </Suspense>
    </>
  );
};

export default App;
