import React, { useMemo } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { LayoutContext } from '@layouts';
import { StackProps } from './types';
import { stripPrefix } from './utils/normalizePath';
import { store } from './store/store';
import { useLocationOverride } from './utils/LocationOverride';
import { benefitColorLookup } from '@app/utils/color';
import { Helmet } from 'react-helmet';
import { defaultTitle } from '@app/utils/const/webTitle';

export const wrapWithProperties = () => {};

export const Screen = ({ name, layout, Component, data = {} }) => {
  const location = useLocationOverride();
  const params = useParams();
  const options = store.getState().getOptions(name);

  const props = useMemo(() => {
    const navigateState = typeof location?.state === 'object' ? location.state : {};
    const searchState = queryString.parse(location?.search);

    // stash the params on the stoer
    store.setState({ matchParams: params });

    return {
      ...searchState,
      ...navigateState,
      ...params,
      ...data,
    };
  }, [location, params, data]);

  const accentColor = useMemo(() => {
    if (typeof options.accentColor === 'function') {
      return options.accentColor(props);
    }

    if (typeof options.accentColor === 'string') {
      return options.accentColor;
    }

    // otherwise, default to the benefit color lookup
    return benefitColorLookup({ slug: params?.slug });
  }, [options.accentColor, props, params]);

  const title = useMemo(() => {
    if (typeof options.title === 'function') {
      return options.title(props);
    }
    return options.title || '';
  }, [options.title, props]);

  return (
    <>
      <Helmet titleTemplate={`%s | ${defaultTitle}`}>
        <title>{title}</title>
      </Helmet>
      <LayoutContext name={name} layout={layout} accentColor={accentColor} needsScroll>
        <Component {...props} />
      </LayoutContext>
    </>
  );
};

const CatchStack: React.FC<StackProps> = ({
  stackName,
  screens,
  data = {},
  onCompleted, // @todo
}) => {
  const location = useLocationOverride();

  return (
    <Routes location={location.override ? location : undefined}>
      {screens.map(({ component, name }) => {
        return (
          <Route
            key={name}
            path={stripPrefix(stackName, name)}
            element={
              <Screen
                name={name}
                layout={location.layout || 'page'}
                Component={component}
                data={data}
              />
            }
          />
        );
      })}
    </Routes>
  );
};

export default React.memo(CatchStack);
